/*=============================================
=            Auto Grid Layout                 =
===============================================
↓ Automatically layout its children in a grid.
--grid-min-item-size: CSS value to change the minimum size of each item.
--grid-placement: auto-fill || auto-fit 
---------------------------------------------
<parent class="auto-grid">
  <child>...</child>
  <child>...</child>
</parent>
=============================================*/
.auto-grid {
  --grid-min-item-size: 20rem;
  --grid-placement: auto-fill;
  display: grid;
  gap: var(--space, var(--space-3xs, 0.125rem));
  grid-template-columns: 1fr;
}
/* When supported by the browser, uses the grid layout else keep a single column */
@supports (width: min(var(--grid-min-item-size), 100%)) {
  .auto-grid {
    grid-template-columns: repeat(var(--grid-placement), minmax(min(var(--grid-min-item-size), 100%), 1fr));
  }
}

/*=============================================
=            The sidebar                     =
==============================================
USAGE ↓
<parent class="with-sidebar">
  <child class="with-sidebar__sidebar">...</child>
  <child class="with-sidebar__main">...</child>
</parent>
=============================================*/
.with-sidebar {
  /** ↓ SidebarWidth in px. The minimum width of the sidebar element in the horizontal configuration. Works better with fixed values */
  --sidebar-width-value: 300;
  /** ↓ ContentMin in %. The minimum width of the content element in the horizontal configuration. Prefer % values */
  --content-min-value: 65;
  /** ↓ Gap/Gutter space between the main content and the sidebar */
  --space: 1rem;
  /** ↓ If you want the sidebar to be at the bottom in the vertical configuration, set it to wrap-reverse. */
  --flex-wrap: wrap;
  /** ↓ Computed variables */
  --content-min: calc(var(--content-min-value) * 1%);
  --sidebar-width: calc(var(--sidebar-width-value) * 1px);
  display: flex;
  flex-wrap: var(--flex-wrap, wrap);
  gap: var(--space, 1rem);
  justify-content: stretch;
  /* align-items: var(--vertical-alignment, stretch);  */
  &__sidebar {
    flex-basis: var(--sidebar-width, auto);
    flex-grow: 1;
  }
  &__main {
    flex-basis: 0;
    flex-grow: 999;
    min-width: var(--content-min, 50%);
  }
}

/*=============================================
=            Cover                            =
---------------------------------------------
<parent class="cover">
  <child>...</child>
  <child class="cover__centered">...</child>
  <child>...</child>
</parent>
=============================================*/
.cover {
  --padding: 1rem;
  --min-height: 50vh;
  --space: 1rem;
  display: flex;
  flex-direction: column;
  min-height: var(--min-height);
  /* ↓ noPad padding: 0; */
  padding: var(--padding);
  > * {
    margin-top: var(--space);
    margin-bottom: var(--space);
  }
  > :first-child:not(.cover__centered) {
    margin-top: 0;
  }
  > :last-child:not(.cover__centered) {
    margin-bottom: 0;
  }
  /* ↓ .cover__centered is the centered element  */
  > .cover__centered {
    margin-top: auto;
    margin-bottom: auto;
  }
}

/*=============================================
=            Stack           =
=============================================
-----------------------------------------------------------
The Stack: Can be used for a vertical list of items
** uses margins instead of gaps for better browser support 
-----------------------------------------------------------
=============================================*/
.stack {
  --align: normal;
  --justify: flex-start;
  --space: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: var(--align);
  justify-content: var(--justify);
  > * + * {
    margin-top: var(--space, 1.5rem);
  }
}

/* The Switcher: alternate between vertical and horizontal configuration
* depending on the provided threshold
-----------------------------------------------------------------------*/
.switcher {
  --space: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space);
  > * {
    flex-grow: 1;
    flex-basis: calc((var(--threshold, 30rem) - 100%) * 999);
  }
}

/* Horizontally padded container (responisve) */
.padded-container {
  --padding: var(--space-xs-6xl, 1rem);
  padding-right: var(--padding);
  padding-left: var(--padding);
}

/* Horizontal Constained Container */
.measured-container {
  box-sizing: content-box;
  max-width: var(--measure, 100rem);
  margin-left: auto;
  margin-right: auto;
}
