/*
=============================================
=            TYPOGRAPHY                     =
=============================================
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font-family);
  font-weight: 600;
}

.h1,
.h2,
.h3,
.h4 {
  font-family: var(--heading-font-family);
  font-weight: 600;
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 1.5rem;
}

h3,
.h3 {
  font-size: 1.25rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

/*=============================================
=     Utilies include single declarations      =
=============================================*/
.pre-line {
  white-space: pre-line;
}
/* Visually hide an element & remains vsible for screen reader */
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
