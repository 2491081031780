/*=============================================
=                     Tokens            =
-----------------------------------------------
Reusable tokens file. Contains:
- A responsive spacing system
- A color palette  
=============================================*/

:root {
  /**
    IMPORTANT: These styles will not be herited by some overlay components (menus, dialogs);
  */
  // https://uicolors.app/edit?sv1=blue:50-f5f9ff/100-ebf2ff/200-d6e6ff/300-b3cdff/400-85abff/500-527dff/600-2e58ff/700-143ceb/800-1233c4/900-112ba2;jumbo:50-fcfcfd/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  --blue-50: #f5f9ff;
  --blue-100: #ebf2ff;
  --blue-200: #d6e6ff;
  --blue-300: #b3cdff;
  --blue-400: #85abff;
  --blue-500: #527dff;
  --blue-600: #2e58ff;
  --blue-700: #143ceb;
  --blue-800: #1233c4;
  --blue-900: #112ba2;
  // https://uicolors.app/edit?sv1=nestor:50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // https://uicolors.app/edit?sv1=blue:50-f5f6ff/100-ebedff/200-d6dcff/300-b3baff/400-858dff/500-5252ff/600-372dff/700-2516eb/800-1f12c5/900-1b11a1;jumbo:50-fcfcfd/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // NESTOR
  --primary-50: #f5f6ff;
  --primary-100: #ebedff;
  --primary-200: #d6dcff;
  --primary-300: #b3baff;
  --primary-400: #858dff;
  --primary-500: #5252ff;
  --primary-600: #372dff;
  --primary-700: #2516eb;
  --primary-800: #1f12c5;
  --primary-900: #1b11a1;

  // https://uicolors.app/edit?sv1=blue:50-f5f5ff/100-ecebff/200-d8d6ff/300-b8b3ff/400-9185ff/500-6e52ff/600-5b2eff/700-4614eb/800-3c12c4/900-3311a2;jumbo:50-fcfcfd/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // Indigo
  --indigo-50: #f5f5ff;
  --indigo-100: #ecebff;
  --indigo-200: #d8d6ff;
  --indigo-300: #b8b3ff;
  --indigo-400: #9185ff;
  --indigo-500: #6e52ff;
  --indigo-600: #5b2eff;
  --indigo-700: #4614eb;
  --indigo-800: #3c12c4;
  --indigo-900: #3311a2;

  // https://uicolors.app/edit?sv1=blue:50-f7f5ff/100-efebff/200-ded6ff/300-c4b3ff/400-a585ff/500-8b52ff/600-7e2eff/700-6a14eb/800-5912c4/900-4b11a2;jumbo:50-fcfcfd/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // Purple
  --purple-50: #f7f5ff;
  --purple-100: #efebff;
  --purple-200: #ded6ff;
  --purple-300: #c4b3ff;
  --purple-400: #a585ff;
  --purple-500: #8b52ff;
  --purple-600: #7e2eff;
  --purple-700: #6a14eb;
  --purple-800: #5912c4;
  --purple-900: #4b11a2;

  // https://uicolors.app/edit?sv1=blue:50-f5feff/100-ebfcff/200-d6faff/300-b3f4ff/400-85e9ff/500-52d4ff/600-2ec0ff/700-14a7eb/800-128cc4/900-1174a2;jumbo:50-fcfcfd/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // Cyan
  --cyan-50: #f5feff;
  --cyan-100: #ebfcff;
  --cyan-200: #d6faff;
  --cyan-300: #b3f4ff;
  --cyan-400: #85e9ff;
  --cyan-500: #52d4ff;
  --cyan-600: #2ec0ff;
  --cyan-700: #14a7eb;
  --cyan-800: #128cc4;
  --cyan-900: #1174a2;

  // https://uicolors.app/edit?sv1=blue:50-d4ffc7/100-bcffad/200-a3ff8a/300-76ff57/400-3cff1a/500-12d600/600-05a300/700-0a5e08/800-073d06/900-052a04;jumbo:50-f6f7f8/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // Green
  --green-50: #d4ffc7;
  --green-100: #bcffad;
  --green-200: #a3ff8a;
  --green-300: #76ff57;
  --green-400: #3cff1a;
  --green-500: #12d600;
  --green-600: #05a300;
  --green-700: #0a5e08;
  --green-800: #073d06;
  --green-900: #052a04;

  // https://uicolors.app/edit?sv1=blue:50-fff5f6/100-ffebed/200-ffd6dc/300-ffb3ba/400-ff8d85/500-ff5252/600-ff382e/700-eb2314/800-c41e12/900-a21b11;jumbo:50-f6f7f8/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // Red
  --red-50: #fff5f8;
  --red-100: #ffebf0;
  --red-200: #ffd6e2;
  --red-300: #ffb3c7;
  --red-400: #ff858d;
  --red-500: #ff5252;
  --red-600: #ff2e38;
  --red-700: #eb1423;
  --red-800: #c4121e;
  --red-900: #a2111b;

  --text-color-error: var(--red-600);
  --danger-color: var(--red-600);
  --warning-color: var(--orange-600);
  --success-color: var(--green-500);
  --info-color: var(--blue-600);
  --help-color: var(--purple-600);
  // Plan-based color tokens
  --upgrade-color: var(--yellow-300, #f6c050);
  --enterprise-color: #1b1680;
  --pro-color: #372dff;
  --starter-color: #ff001e;

  --primary-color-rgb: 79, 70, 229;
  --danger-color-rgb: 239, 68, 68;
  --danger-background-color: var(--red-100);
  --warning-color: var(--orange-500);
  /* var(--orange-500) */
  --warning-color-rgb: 249, 115, 22;
  --success-color-rgb: 34, 197, 94;
  --success-background-color: var(--green-100);
  // --info-color-rgb: 82, 125, 255;
  // --info-background-color: var(--primary-100);
  --help-color-rgb: 168, 85, 247;
  --secondary-color: var(--bluegray-500);
  --secondary-color-rgb: 100, 116, 139;

  --primary-color-hover: var(--primary-600);
  --background-hover-color: rgba(var(--primary-color-rgb), 0.08);

  --heading-font-family: "IBM Plex Sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

  --primary-color-rgb: 66, 69, 255;
  --brand-background-color: #fcfcfe;

  /* Overwrite theme colors with custom colors */
  --primary-linear-color: linear-gradient(var(--primary-400) 0%, var(--primary-600) 100%) var(--primary-500);
  --primary-color-hover: var(--primary-400);

  /* Focus colors */
  --color-focus: var(--blue-400);
  --color-focus-shadow: var(--blue-100);

  /* Used as the maximun number of characters to ensure legibility (maximum width allowed)*/
  --measure: 100rem;

  /**
     * Fluid spacing is a system for spacing that scales based on the size of the screen.
     * https://utopia.fyi/space/calculator?c=390,21,1.2,1140,24,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|4|4|6|20,xs-xl
     * clamp([minimum size], [viewport based computed value], [maximum size]) 
     */
  --space-3xs: clamp(0.31rem, calc(0.28rem + 0.13vw), 0.38rem);
  --space-2xs: clamp(0.69rem, calc(0.66rem + 0.13vw), 0.75rem);
  --space-xs: clamp(1rem, calc(0.94rem + 0.27vw), 1.13rem);
  --space-s: clamp(1.31rem, calc(1.22rem + 0.4vw), 1.5rem);
  --space-m: clamp(2rem, calc(1.87rem + 0.53vw), 2.25rem);
  --space-l: clamp(2.63rem, calc(2.43rem + 0.8vw), 3rem);
  --space-xl: clamp(3.94rem, calc(3.65rem + 1.2vw), 4.5rem);
  --space-2xl: clamp(5.25rem, calc(4.86rem + 1.6vw), 6rem);
  --space-3xl: clamp(6.56rem, calc(6.08rem + 2vw), 7.5rem);
  --space-4xl: clamp(7.88rem, calc(7.29rem + 2.4vw), 9rem);
  --space-5xl: clamp(9.19rem, calc(8.51rem + 2.8vw), 10.5rem);
  /* Custom pairs (from one size to another one) */
  --space-2xs-m: clamp(0.69rem, calc(-0.12rem + 3.33vw), 2.25rem);
  --space-xs-xl: clamp(1rem, calc(-0.82rem + 7.47vw), 4.5rem);
  --space-xs-6xl: clamp(1rem, calc(-3.16rem + 17.07vw), 9rem);
  --space-xs-19xl: clamp(1rem, calc(-14.08rem + 61.87vw), 30rem);
  --space-6xl-10xl: clamp(9.19rem, calc(6.17rem + 12.4vw), 15rem);
  --space-7xl-13xl: clamp(11.81rem, calc(6.26rem + 22.8vw), 22.5rem);
}
