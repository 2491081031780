@import "tokens.scss";
@import "utilities.scss";
@import "layouts.scss";

// Plugins
@import "plugins/intl-tel-input.scss";
@import "plugins/tooltip.scss";

/*=============================================
=       Global styles - applies everywhere     =
=============================================*/
html,
body {
  height: 100%;
  font-size: 15px;
  margin: 0px;
  font-family: var(--font-family);
}
body {
  background-color: var(--brand-background-color);
}
/* Enlarge menuItem clickable zone */
.p-menuitem-link {
  --menuitem-link-padding-y: 1rem;
  --menuitem-link-padding-x: 1rem;
}
/* Default background set to primary color with white text */
p-avatar {
  --background-active-color: var(--primary-500);
  color: var(--surface-0);
  /* Inactive state  */
  &.inactive {
    --background-active-color: var(--surface-400);
  }
}
/* Remove primary color from input borders as it ressembles an error style */
.p-inputtext:enabled:focus,
.phone-input:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--color-focus);
}
/* Custom focus styles */
.p-inputtext:enabled:focus,
.p-button:focus,
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus,
.phone-input:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem var(--color-focus-shadow);
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 1.25rem;
}
.p-datatable.p-component {
  box-shadow: var(--table-shadow, none);
  border-radius: var(--table-border-radius, 0);
  overflow: var(--table-overflow, visible);
}
.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
  min-width: auto !important;
  width: calc(100% - var(--padding) * 2) !important;
  max-width: var(--measure, 100rem) !important;
}
/* Bold field labels */
.field label {
  font-weight: 500;
}
/* ANIMATIONS & TRANSITIONS */
.p-inputtext,
.p-button {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-disabled,
.p-component:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: var(--color-disabled, var(--text-color));
  background-color: var(--background-disabled, var(--surface-200));
  border-color: var(--background-disabled, var(--surface-200));
}
.pagination-button {
  --color-disabled: var(--text-color-secondary);
  --background-disabled: transparent;
}

/*=============================================
=  Custom Components - standalone components      =
=============================================*/
/* Postcard  */
.postcard-container {
  --postcard-blue: #3039f5;
  --postcard-red: #eb332f;
  --postcard-white: #ffffff;
  --strip-width: 10px;
  box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.25);
  background: repeating-linear-gradient(
    135deg,
    var(--postcard-blue),
    var(--postcard-blue) calc(var(--strip-width) * 1),
    var(--postcard-white) calc(var(--strip-width) * 1),
    var(--postcard-white) calc(var(--strip-width) * 2),
    var(--postcard-red) calc(var(--strip-width) * 2),
    var(--postcard-red) calc(var(--strip-width) * 3),
    var(--postcard-white) calc(var(--strip-width) * 3),
    var(--postcard-white) calc(var(--strip-width) * 4)
  );
  padding: 8px;
  border-radius: 0.5rem;
  max-width: var(--measure, 100ch);

  .postcard {
    --cell-gutter: var(--space-3xs);
    --postcard-gutter: var(--space-s);
    display: grid;
    grid-template-columns: 1fr;
    background: var(--surface-0, #fff);
    border-radius: inherit;
    padding: var(--space-m, 1.5rem) var(--space-2xs-m, 0.5rem);
    gap: var(--postcard-gutter, 1rem);
    &__header {
      gap: var(--postcard-gutter, 1rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap-reverse;
    }
    &__topic {
      font-weight: 600;
    }
    &__cell {
      /* Use .postcard__cell with .stack */
      --space: var(--cell-gutter, 0.125rem);
      &__label {
        font-weight: 500;
      }
      &__content {
        color: var(--text-color-secondary);
      }
    }
  }
}
/* Recipients Input Chips  */
.recipients-custom-chips {
  .p-chips-token {
    padding-right: 0.75rem !important;
    padding-left: 0 !important;
    border: 1px solid transparent;
    .recipients-custom-chips-symbol {
      width: 2rem;
      margin-right: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .recipients-custom-chips-text {
      line-height: 1;
      margin-left: 0.5rem;
    }
    &.data-chip-invalid {
      border: 1px dashed var(--red-500);
      background-color: var(--red-50);
      .p-chips-token-icon {
        color: var(--red-500);
      }
    }
  }
  .p-chips-multiple-container {
    gap: 0.5rem 0rem;
  }
}

/*     Switch Field    
-----------------------------------------------
Clickable switch field built on prime NG's,
Usage:
<label class="switch-field">
  <div class="field-text">
    <span class="field-text__title">...</span>
    <span class="field-text__subtitle>...</span>
  </div>
  <p-inputSwitch></p-inputSwitch>
</label>
-----------------------------------------------*/
label.switch-field {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--space-xs, 8px);
  padding-bottom: var(--space-xs, 8px);
  gap: var(--space-2xs, 4px);
  margin-bottom: 0;
  .field-text {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--space-3xs, 8px);
    /* Prevent text selection  */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    &__title {
      font-weight: 500;
    }
    &__subtitle {
      color: var(--text-color-secondary);
      font-size: 0.875rem;
    }
  }
}

/*=============================================
=   Modifiers - applies where specified      =
=============================================*/

/* Legacy Primary Button */
.bg-primary-linear:not([disabled]) {
  // background: var(--primary-linear-color);
  background-color: var(--primary-color);
  // box-shadow: var(--primary-400) 0px 8px 16px -8px;
}
/* Use to for forcibly hide button labels */
[data-button~="noLabel"] .p-button-label {
  display: none !important;
}
[data-paginator="hide"] .p-paginator {
  display: none;
}
/* Styles the timeline component used as a stepper  */
[data-timeline~="truncated"] {
  .p-timeline-event-opposite,
  .p-timeline-event-content {
    max-width: calc(var(--available-space, 50%) - 1rem);
  }
  .p-timeline-event-opposite *,
  .p-timeline-event-content * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
[data-timeline~="opposite:none"] {
  --available-space: 100%;
  .p-timeline-event-opposite {
    flex: 0;
    padding: 0 !important;
  }
}
/** Record Tags Input*/
.tags-custom-chips .p-chips {
  display: block;
  width: 100%;
}
